async function handleRequest(url, config) {
  const response = await window.fetch(`${url}`, config);

  if (response.ok) {
    return response.json();
  }

  const errorMessage = await response.text();
  return Promise.reject(new Error(errorMessage));
}

export async function post(url, { data, ...customConfig } = {}) {
  const config = {
    method: 'POST',
    ...customConfig,
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  return handleRequest(url, config);
}

export async function get(url, { data, ...customConfig } = {}) {
  const config = {
    method: 'GET',
    ...customConfig,
  };

  return handleRequest(
    data ? `${url}?${new URLSearchParams(data)}` : url,
    config,
  );
}

export default async function customFetch(url, { data, ...customConfig } = {}) {
  let fetchUrl;

  const config = {
    method: 'GET',
    ...customConfig,
  };

  if (data && config.method === 'POST') {
    config.body = JSON.stringify(data);
  }

  if (data && config.method === 'GET') {
    fetchUrl = `${url}?${new URLSearchParams(data)}`;
  } else {
    fetchUrl = url;
  }

  const response = await window.fetch(`${fetchUrl}`, config);

  if (response.ok) {
    return response.json();
  }

  const errorMessage = await response.text();
  return Promise.reject(new Error(errorMessage));
}
