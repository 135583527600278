import { render } from 'preact';
import { html } from 'htm/preact';
import IssuuFetcher from './issuu-fetcher';

export default function init() {
  const roots = document.querySelectorAll('.issuu-loader');

  [...roots].forEach((root) => {
    render(html`<${IssuuFetcher} />`, root);
  });
}
