import Glide, { Autoplay } from '@glidejs/glide/dist/glide.modular.esm';
import { get } from '../../utilities/fetch';

function getAds() {
  return get('/wp-json/seutumajakka/v1/common-column-ads');
}

export default async function init() {
  let result;
  const body = document.querySelector('body');

  // TODO: Parse values.
  const disableLeftColumnAd = body.dataset.disableLeftAd === '1';
  const disableRightColumnAd = body.dataset.disableRightAd === '1';

  const leftColumns = [
    ...document.querySelectorAll('.single-classified .column--left'),
    ...document.querySelectorAll('.single-article .column--left'),
  ];

  const rightColumns = [
    ...document.querySelectorAll('.wp-block-columns .wp-block-column[style*="33.33%"]:last-child'),
    ...document.querySelectorAll('.single-classified .column--right'),
    ...document.querySelectorAll('.single-article .column--right'),
  ];

  const useLeftColumns = leftColumns.length && !disableLeftColumnAd;
  const useRightColumns = rightColumns.length && !disableRightColumnAd;

  if (useLeftColumns || useRightColumns) {
    result = await getAds();
  }

  if (useLeftColumns) {
    leftColumns[0].insertAdjacentHTML('afterbegin', result.left_ads_rendered);
  }

  if (useRightColumns) {
    rightColumns[0].insertAdjacentHTML('afterbegin', result.right_ads_rendered);
  }

  const adSliderRoots = document.querySelectorAll('.small-ad-carousel');

  [...adSliderRoots].forEach((root) => {
    const timeout = parseInt(root.dataset.timeout, 10);
    const glideRoot = root.querySelector('.glide');

    new Glide(glideRoot, {
      autoplay: timeout || 15000,
      gap: 40,
    }).mount({ Autoplay });
  });
}
