import { render } from 'preact';
import { html } from 'htm/preact';
import ClassifiedsFetcher from './classifieds-fetcher';

export default function init() {
  const roots = document.querySelectorAll('.classifieds-loader');

  [...roots].forEach((root) => {
    render(html`<${ClassifiedsFetcher} />`, root);
  });
}
