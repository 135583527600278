export default function scrollToTargetAdjusted({ targetEl = null, offset = 0 }) {
  const elementTop = Math.round(targetEl.getBoundingClientRect().top + window.pageYOffset);
  const offsetPosition = elementTop + offset;

  window.scrollTo({
    top: offsetPosition,
    left: 0,
    behavior: 'smooth',
  });
}
