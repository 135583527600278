import navPanel from '../../components/nav-panel/nav-panel';
import navMobile from '../../components/nav-mobile/nav-mobile';
import topBar from '../../components/top-bar/top-bar';
import classifiedListing from '../../blocks/classifieds-listing/classifieds-listing';
import articleArchive from '../../blocks/article-archive/article-archive';
import videoArchive from '../../blocks/video-archive/video-archive';
import issuuArchive from '../../blocks/issuu-archive/issuu-archive';
import commonSmallAds from '../../blocks/small-ad/common-column-ads';
import fullSizeAdCarousel from '../../blocks/full-size-ad/full-size-ad-carousel';
import { handleObfuscatedEmails } from '../../utilities/handle-obfuscated-emails';

const appStart = () => {
  navPanel.init();
  navMobile.init();
  topBar.init();

  classifiedListing();
  articleArchive();
  videoArchive();
  issuuArchive();
  commonSmallAds();
  fullSizeAdCarousel();
  handleObfuscatedEmails();
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}
