/* eslint-disable indent */
import { useEffect, useState, useRef } from 'preact/hooks';
import { html } from 'htm/preact';
import classNames from 'classnames';

import { get } from '../../utilities/fetch';
import scrollToTarget from '../../utilities/scroll-to-target-adjusted';

// This actually gets POSTS_PER_PAGE + 1 for the first page for layout reasons, see endpoints.php.
// This should by divisible by 3.
const POSTS_PER_PAGE = 9;
const PAGINATION_LIMIT = 5;

export default function VideoFetcher({ ads = [], category = -1 }) {
  const [isLoading, setIsLoading] = useState(false);
  const [postsHTML, setPostsHTML] = useState('');
  const [totalPosts, setTotalPosts] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const root = useRef(null);

  const totalPages = totalPosts > 0
    ? Math.ceil(totalPosts / POSTS_PER_PAGE)
    : 0;

  const totalPagesArray = [...Array(totalPages + 1).keys()].slice(1);
  const pagesBetweenOneAndLastArray = totalPagesArray.slice(1).slice(0, -1);

  const currentPageIndex = pagesBetweenOneAndLastArray.findIndex((i) => i === activePage);
  const startCurrentPagesIndex = Math.max(0, currentPageIndex);

  const isIndexNearEnd = startCurrentPagesIndex
    > pagesBetweenOneAndLastArray.length - (1 + PAGINATION_LIMIT);

  const currentPagesArray = isIndexNearEnd
    ? pagesBetweenOneAndLastArray.slice(-1 * PAGINATION_LIMIT)
    : pagesBetweenOneAndLastArray.slice(
      startCurrentPagesIndex,
      startCurrentPagesIndex + PAGINATION_LIMIT,
    );

  const lastPageOfTotalPages = totalPagesArray.slice(-1)[0];
  const lastPageOfCurrentPages = currentPagesArray.slice(-1)[0];
  const hasPagesBeforeCurrentPagination = (currentPagesArray[0] > 2);
  const hasPagesAfterCurrentPagination = (lastPageOfCurrentPages < lastPageOfTotalPages - 1);

  async function fetchVideos({ page }) {
    const params = {
      page,
      per_page: POSTS_PER_PAGE,
      type: 'post',
      term_id: category,
      taxonomy: 'category',
      ads,
    };

    setIsLoading(true);

    try {
      const result = await get('/wp-json/seutumajakka/v1/videos-by-taxonomy-term', { data: params });

      setPostsHTML(result.posts_rendered);
      setTotalPosts(result.total_posts);
    } catch (err) {
      // TODO: Error.
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setActivePage(1);
    fetchVideos({ termId: category, page: 1 });
  }, []);

  async function handlePaginationClick({ page }) {
    setActivePage(page);
    await fetchVideos({ termId: category, page });

    if (root.current) {
      scrollToTarget({
        targetEl: root.current,
        offset: -400,
      });
    }
  }

  const containerClasses = classNames('videos-loader__container', {
    'is--loading': isLoading,
  });

  const isActivePageFirst = (activePage === totalPagesArray.slice(0, 1)[0]);
  const isActivePageLast = (activePage === totalPagesArray.slice(-1)[0]);

  return html`
    <div className=${containerClasses} ref=${root}>
      <div className="videos__listing">
        <div className="posts" dangerouslySetInnerHTML=${{ __html: postsHTML }} />
      </div>

      ${totalPagesArray.length > 1 && html`
        <div className="videos__pagination">
          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${isActivePageFirst}
            onClick=${() => {
              handlePaginationClick({ page: activePage - 1 });
            }}
          >
            Edellinen
          </button>

          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${activePage === 1}
            data-active=${activePage === 1}
            onClick=${() => {
              handlePaginationClick({ page: 1 });
            }}
          >
            1
          </button>

          ${hasPagesBeforeCurrentPagination && html`
            <span className="ellipses">…</span>
          `}

          ${currentPagesArray.map((item) => html`
            <button
              type="button"
              class="btn  btn--transparent"
              data-active=${activePage === item}
              disabled=${activePage === item}
              onClick=${() => {
                handlePaginationClick({ page: item });
              }}
            >
              ${item}
            </button>
          `)}

          ${hasPagesAfterCurrentPagination && html`
            <span className="ellipses">…</span>
          `}

          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${activePage === lastPageOfTotalPages}
            data-active=${activePage === lastPageOfTotalPages}
            onClick=${() => {
              handlePaginationClick({ page: lastPageOfTotalPages });
            }}
          >
            ${lastPageOfTotalPages}
          </button>

          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${isActivePageLast}
            onClick=${() => {
              handlePaginationClick({ page: activePage + 1 });
            }}
          >
            Seuraava
          </button>
        </div>`
      }
    </div>
  `;
}
