export const rot13 = (message) => {
  const alpha = 'abcdefghijklmnopqrstuvwxyzabcdefghijklmABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLM';
  return message.replace(/[a-z]/gi, (letter) => alpha[alpha.indexOf(letter) + 13]);
};

export const handleObfuscatedEmails = () => {
  const obfuscatedEmails = document.querySelectorAll('a.email-ob');

  [...obfuscatedEmails].forEach((item) => {
    const { href } = item.dataset;
    setTimeout(() => {
      item.setAttribute('href', rot13(href));
      item.classList.remove('disabled');
    }, 2000);
  });
};
