import { render } from 'preact';
import { html } from 'htm/preact';
import ArticleFetcher from './article-fetcher';

export default function init() {
  const roots = document.querySelectorAll('.articles-loader');

  [...roots].forEach((root) => {
    const { ads, category } = root.dataset;
    let adsParsed = '';
    let categoryParsed = -1;

    try {
      adsParsed = JSON.parse(ads);
    } catch (err) {
      // Do nothing.
    }

    try {
      categoryParsed = JSON.parse(category);
    } catch (err) {
      // Do nothing.
    }

    render(html`<${ArticleFetcher} ads=${adsParsed} category=${categoryParsed} />`, root);
  });
}
