/* eslint-disable indent */
import { useEffect, useState } from 'preact/hooks';
import { html } from 'htm/preact';
import classNames from 'classnames';

import { get } from '../../utilities/fetch';

const POSTS_PER_PAGE = 5;
const PAGINATION_LIMIT = 5;

export default function ClassifiedsFetcher() {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTermId, setActiveTermId] = useState(-1);
  const [categories, setCategories] = useState([]);
  const [postsHTML, setPostsHTML] = useState('');
  const [totalPosts, setTotalPosts] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const totalPages = totalPosts > 0
    ? Math.ceil(totalPosts / POSTS_PER_PAGE)
    : 0;

  const totalPagesArray = [...Array(totalPages + 1).keys()].slice(1);
  const pagesBetweenOneAndLastArray = totalPagesArray.slice(1).slice(0, -1);

  const currentPageIndex = pagesBetweenOneAndLastArray.findIndex((i) => i === activePage);
  const startCurrentPagesIndex = Math.max(0, currentPageIndex);

  const isIndexNearEnd = startCurrentPagesIndex
    > pagesBetweenOneAndLastArray.length - (1 + PAGINATION_LIMIT);

  const currentPagesArray = isIndexNearEnd
    ? pagesBetweenOneAndLastArray.slice(-1 * PAGINATION_LIMIT)
    : pagesBetweenOneAndLastArray.slice(
      startCurrentPagesIndex,
      startCurrentPagesIndex + PAGINATION_LIMIT,
    );

  const lastPageOfTotalPages = totalPagesArray.slice(-1)[0];
  const lastPageOfCurrentPages = currentPagesArray.slice(-1)[0];
  const hasPagesBeforeCurrentPagination = (currentPagesArray[0] > 2);
  const hasPagesAfterCurrentPagination = (lastPageOfCurrentPages < lastPageOfTotalPages - 1);

  async function fetchCategories() {
    setIsLoading(true);

    try {
      const result = await get('/wp-json/wp/v2/seutu_classified_cat', {
        data: {
          per_page: 30,
          orderby: 'id',
          order: 'asc',
          // hide_empty: true,
        },
      });

      setCategories(result);
    } catch (err) {
      // TODO: Failed.
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchClassifieds({ termId, page }) {
    const params = {
      page,
      per_page: POSTS_PER_PAGE,
      type: 'seutu_classified',
      term_id: termId,
      taxonomy: 'seutu_classified_cat',
    };

    setIsLoading(true);

    try {
      const result = await get('/wp-json/seutumajakka/v1/classifieds-by-taxonomy-term', { data: params });

      setPostsHTML(result.posts_rendered);
      setTotalPosts(result.total_posts);
    } catch (err) {
      // TODO: Error.
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCategories();
    setActivePage(1);
    fetchClassifieds({ termId: -1, page: 1 });
  }, []);

  function handleCategoryClick({ active, termId }) {
    if (active) {
      setActiveTermId(-1);
      setActivePage(1);
      fetchClassifieds({ termId: -1, page: 1 });
    } else {
      setActiveTermId(termId);
      setActivePage(1);
      fetchClassifieds({ termId, page: 1 });
    }
  }

  function handlePaginationClick({ page }) {
    setActivePage(page);
    fetchClassifieds({ termId: activeTermId, page });
  }

  const containerClasses = classNames('classifieds-loader__container', {
    'is--loading': isLoading,
  });

  const isActivePageFirst = (activePage === totalPagesArray.slice(0, 1)[0]);
  const isActivePageLast = (activePage === totalPagesArray.slice(-1)[0]);

  return html`
    <div className=${containerClasses}>
      <div className="classifieds__shortcuts">
        <button
          className="btn  btn--transparent"
          type="button"
          disabled=${(activeTermId === -1)}
          onClick=${() => {
            handleCategoryClick({
              active: (activeTermId === -1),
              termId: -1,
            });
          }}
          data-active=${(activeTermId === -1)}
        >
          Kaikki
        </button>
        ${categories.map((cat) => {
          const isActive = cat.id === activeTermId;

          return html`
            <button
              className="btn  btn--transparent"
              type="button"
              disabled=${isActive}
              onClick=${() => {
                handleCategoryClick({
                  active: isActive,
                  termId: cat.id,
                });
              }}
              data-active=${isActive}
            >
              ${cat.name}
            </button>
          `;
        })}
      </div>

      <div className="classifieds__listing">
        <div className="posts" dangerouslySetInnerHTML=${{ __html: postsHTML }} />
      </div>

      ${totalPagesArray.length > 1 && html`
        <div className="classifieds__pagination">
          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${isActivePageFirst}
            onClick=${() => {
              handlePaginationClick({ page: activePage - 1 });
            }}
          >
            Edellinen
          </button>

          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${activePage === 1}
            data-active=${activePage === 1}
            onClick=${() => {
              handlePaginationClick({ page: 1 });
            }}
          >
            1
          </button>

          ${hasPagesBeforeCurrentPagination && html`
            <span className="ellipses">…</span>
          `}

          ${currentPagesArray.map((item) => html`
            <button
              type="button"
              class="btn  btn--transparent"
              data-active=${activePage === item}
              disabled=${activePage === item}
              onClick=${() => {
                handlePaginationClick({ page: item });
              }}
            >
              ${item}
            </button>
          `)}

          ${hasPagesAfterCurrentPagination && html`
            <span className="ellipses">…</span>
          `}

          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${activePage === lastPageOfTotalPages}
            data-active=${activePage === lastPageOfTotalPages}
            onClick=${() => {
              handlePaginationClick({ page: lastPageOfTotalPages });
            }}
          >
            ${lastPageOfTotalPages}
          </button>

          <button
            type="button"
            class="btn  btn--transparent"
            disabled=${isActivePageLast}
            onClick=${() => {
              handlePaginationClick({ page: activePage + 1 });
            }}
          >
            Seuraava
          </button>
        </div>`
      }
    </div>
  `;
}
